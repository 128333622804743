if (!window.fetch) {
    import('whatwg-fetch');
}

import LazyLoad from 'vanilla-lazyload';

if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

import advancedSearch from './modules/advanced-search';
import amountHandler from './modules/amount-handler';
import availabilityPopup from './modules/availability-popup';
import banners from './modules/banners';
import buttonEvents from './modules/button-events';
import functionModals from './modules/function-modals';
import cookies from './modules/general/cookies';
import rwd from './modules/general/rwd';
import widgetFacebook from './modules/general/widget-facebook';
//import newsletterPopup from './modules/newsletter-popup';
import pjaxCode from './modules/pjax-code';
//import polls from './modules/polls';
import priceFiltering from './modules/price-filtering';
import productAdder from './modules/product-adder';
import bannersProductCard from './modules/product-detail/banners-product-card';
import comments from './modules/general/comments';
import optionChooser from './modules/product-detail/option-chooser';
import recaptchaInit from './modules/recaptcha';
//import installWorker from './modules/utils/install-worker';
import searchEngine from './modules/search-engine';
import sorter from './modules/sorter';
import tabs from './modules/tabs';
//import treeboxCount from './modules/treebox-count';
import eventReceiver from './modules/utils/event-receiver';
//import performance from './modules/utils/performance';
import wishlist from './modules/wishlist';
import calculateFreeShipment from './modules/general/calculate-free-shipment';
import questionForm from './modules/question-form';
import showcase from './modules/general/showcase';
import initObserver from './modules/general/observer';
import filterNarrowDown from './modules/filter-narrow-down';

document.addEventListener("DOMContentLoaded", function () {
    if ('IntersectionObserver' in window && document.querySelectorAll('[data-run-fancybox]').length > 0) {
        import('@fancyapps/ui/dist/fancybox/fancybox.umd').then(module => {
            module.Fancybox.bind('[data-run-fancybox=""]', {
                autoFocus: false,
                trapFocus: false,
                placeFocusBack: false,
                groupAll: true,
                hideScrollbar: false
            });

            module.Fancybox.bind('[data-run-fancybox="single"]', {
                autoFocus: false,
                trapFocus: false,
                placeFocusBack: false,
                groupAll: false,
                hideScrollbar: false
            });
        });
    }

    //available types of body: cart, listing, detail, mainpage, advanced-search
    const typeOf = document.body.getAttribute('data-type');

    switch (typeOf) {
        case 'cart':
            import('./modules/cart/_index.js').then((module) => {        
                module.cartDeliveryPoints();
                module.cartCommands();
                module.cartManagement();
            });
            break;
        case 'listing':
            pjaxCode();
            priceFiltering();
            filterNarrowDown();
            break;
        case 'detail':
            optionChooser();
            amountHandler();
            bannersProductCard();
            availabilityPopup();
            break;
        case 'mainpage':
            break;
        case 'advanced-search':
            advancedSearch();
            break;
        default:
            break;
    }

    recaptchaInit();
    comments();
    banners();
    buttonEvents();
    cookies();
    //polls();
    rwd();
    //treeboxCount();
    widgetFacebook();
    wishlist();
    sorter();
    //newsletterPopup();
    eventReceiver();
    productAdder();
    tabs();
    searchEngine();
    //performance();
    functionModals();
    //installWorker();
    calculateFreeShipment();
    questionForm();
    showcase();

    // HEADER OBERVER
    const header = document.querySelector('.shop-header');
    const headerSentinel = document.querySelector('.header-sentinel');

    if (header) {
        initObserver(headerSentinel, function () {
            header.classList.add('stuck');
        }, function () {
            header.classList.remove('stuck');
        });
    }

    // VALIDATIONS
    const toValidateList = document.querySelectorAll('[data-run-validator]');

    if (toValidateList.length > 0) {
        import('./modules/front-validation').then(module => module.default(toValidateList));
    }

    // LAZY LOAD IMAGES
    window.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazyload',
        unobserve_entered: true
    });

    // SCROLLING OFFSET
    window.scrollOffset = 20;

    // INIT TOOLTIPS
    const tippyNeeded = document.querySelectorAll('[data-tippy-content]').length > 0;

    if (tippyNeeded) {
        import('tippy.js').then((module) => {
            module.delegate('body', { target: '[data-tippy-content]'});
        });
    }

    // LINKIFY CHAT
    const threadMessages = document.querySelector('.thread-messages');

    if (threadMessages) {
        import('linkify-html').then((module) => {
            const messages = threadMessages.querySelectorAll('.message-body');

            messages.forEach(function (message) {
                const text = message.textContent;
                const linkifiedText = module.default(text, { target: "_blank" });

                message.innerHTML = linkifiedText;
            });
        });
    }

    // MAP OBSERVER
    const footerMap = document.querySelector('#footer .map-wrapper');

    if ('IntersectionObserver' in window) {
        let observer = null;

        if (footerMap) {
            observer = new IntersectionObserver(
                function (entries) {
                    for (var i = 0; i < entries.length; i++) {
                        if (entries[i].isIntersecting) {
                            const iframe = footerMap.querySelector('iframe');

                            iframe.setAttribute('src', iframe.getAttribute('data-src'));
                            observer.unobserve(footerMap);
                        }
                    }
                }
            );

            observer.observe(footerMap);
        }
    } else {
        const iframe = footerMap.querySelector('iframe');
        iframe.setAttribute('src', iframe.getAttribute('data-src'));
    }

    // INP TESTING
    //let worstInp = 0;

    //const observer = new PerformanceObserver((list, obs, options) => {
    //    for (let entry of list.getEntries()) {
    //        if (!entry.interactionId) continue;

    //        entry.renderTime = entry.startTime + entry.duration;
    //        worstInp = Math.max(entry.duration, worstInp);

    //        //console.clear();
    //        console.log('[Interaction]', entry.duration, `type: ${entry.name} interactionCount: ${performance.interactionCount}, worstInp: ${worstInp}`, entry, options);

    //    }
    //});

    //observer.observe({
    //    type: 'event',
    //    durationThreshold: 0,
    //    buffered: true
    //});
});