export default function () {
    const headerShipment = document.querySelector('.header-shipment');

    function calculateShipment() {
        const freeShipmentValue = parseFloat(headerShipment.getAttribute('data-free-shipment-value'));
        const currentPaymentValue = parseFloat(document.querySelector('#cart-box .cart-text-wrapper > span').getAttribute('data-current-payment-value'));
        const diff = +(freeShipmentValue - currentPaymentValue).toFixed(2);

        if (diff > 0) {
            headerShipment.innerHTML = `Do darmowej dostawy brakuje <b class='on'>${diff.toFixed(2).replace('.', ',')} zł</b>`;
        } else {
            headerShipment.innerHTML = `<b class="on">Dostawa gratis!</b>`;
        }
    }

    if (headerShipment) {
        calculateShipment();
    }
}